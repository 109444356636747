import React from 'react'
import { Box } from 'theme-ui'
import { Layout, Stack, Main, Hero } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '../../flow-ui-widgets/Seo/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const sliderRef = React.useRef()

  const sortOrder = ["Tests", "Coulisses", "Analyses", "Archives"];
  const sortedCategories = posts.group.sort((a, b) => sortOrder.indexOf(a.categoryName) - sortOrder.indexOf(b.categoryName));

  return (
    <Layout {...props}>
      <Seo title='Accueil' />
      <Hero
        pt={4}
        pb={5}
        sx={{
          background: t =>
            `linear-gradient(
              0deg,
              ${t.colors.omegaLighter},
              ${t.colors.omegaLight}
            )`
        }}
      >
        <Divider space={3} />
        <CardList
          nodes={featuredPosts.nodes}
          variant={['horizontal-hero']}
          limit={3}
          omitFooter
          slider
          autoPlay
          fade
          arrows={false}
          controlPosition='bottom'
          ref={sliderRef}
          loading='eager'
        />
      </Hero>
      <Divider />
      {/* <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack> */}
      <Divider />
      {posts.group.length && sortedCategories.map((group, index) => {
        // const omitSortiesCategory = group.categoryName === 'Sorties'

        return (
          <React.Fragment key={`${group.categoryName}.list`}>
            <Stack title={group.categoryName} titleLink={group.nodes[0].category.slug}>
              <Main>
                <CardList
                  nodes={group.nodes}
                  variant={'vertical'}
                  limit={6}
                  columns={[1, 1, 3, 3]}
                  omitCategory
                  omitAuthor
                />
              </Main>
            </Stack>
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
          )}
        )}
      <Divider space={5} />
    </Layout>
  )
}

export default Posts
